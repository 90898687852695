.learning-container {
    padding-top: 70px;
    width: 90%;
    margin: 20px auto;
  }
  .course-item {
    width: 200px;
    height: 120px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
    cursor: pointer;
  }
  .course-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
    justify-content: center;
  }
  .course-item-container {
    position: relative;
  }
  .course-name {
    max-width: 155px; /* Adjust as needed */
    white-space: nowrap;
    text-align: center;
    font-size: 12px;
    margin-top: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
  }
  .overlay-button {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 5px;
    padding: 5px;
  }
  
  .overlay-btn {
    padding: 5px 10px;
    font-size: 10px;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  .open-btn {
    background-color: #007bff;
  }
  
  .download-btn {
    background-color: #28a745;
  }
  